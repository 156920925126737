@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

:root {
	--lightBlue: 8, 194, 243;
	--midBlue: 35, 153, 213;
	--darkBlue: 0, 92, 144;
}

html,
body {
	font-family: "Open Sans", sans-serif;
}

body {
	@apply min-h-[100vh];
	&.admin {
		@apply bg-[#F8FBFC];
	}
}

img {
	@apply inline;
}

.admin {
	h2 {
		@apply text-2xl text-sky-900 font-bold mb-5;
	}
	h3 {
		@apply text-lg text-sky-900 font-bold mb-5;
	}
}

.fullTable {
	@apply w-full border-neutral-200 border;
	&__filter {
		@apply px-2 py-1 text-neutral-800 text-xs border border-neutral-300 rounded-md my-2 outline-0 w-full;
	}
	th {
		@apply text-xs text-sky-900 bg-neutral-100 px-3 py-2 border-r border-r-neutral-200 text-left relative;
	}
	tr {
		&:nth-child(even) {
			@apply bg-neutral-100;
		}
	}
	td {
		@apply px-2 py-2 text-sm border border-r-neutral-200 text-neutral-700;
	}
	&__controls {
	}
	&__control {
		@apply border rounded px-3 py-1 bg-midBlue text-white hover:bg-midBlue/90 text-sm font-bold;
		&:disabled {
			@apply bg-midBlue/50 text-white/50 cursor-not-allowed;
		}
	}
	&.mainApp {
		td {
			@apply text-xs;
		}
	}
	.resizer {
		position: absolute;
		top: 0;
		height: 100%;
		right: 0;
		width: 5px;
		background: rgba(0, 0, 0, 0.5);
		cursor: col-resize;
		user-select: none;
		touch-action: none;
	}

	.resizer.isResizing {
		@apply bg-slate-700 opacity-100;
	}

	@media (hover: hover) {
		.resizer {
			opacity: 0;
		}

		*:hover > .resizer {
			opacity: 1;
		}
	}
	&.small {
		th {
			@apply text-xs;
		}
		td {
			@apply text-xs;
		}
	}
}

.formList {
	@apply w-full flex flex-wrap;
	&__item {
		@apply relative flex flex-wrap items-center justify-between border-b border-neutral-200 transition-all duration-500 mb-8;
		@apply w-[30%] pb-3 mr-[1%];
		&.is-valid {
			@apply border-green-500;
		}
		&.is-invalid {
			@apply border-red-500;
		}
		&.full {
			@apply w-full mr-0 mb-0;
		}
		label {
			@apply text-sm text-neutral-500 mb-3 block pt-8;
		}
		input[type="text"],
		input[type="email"],
		input[type="password"],
		input[type="number"],
		input[type="tel"],
		input[type="file"],
		input[type="url"] {
			@apply block border-none outline-0 bg-transparent w-full py-1 px-1 text-neutral-600;
		}
		textarea {
			@apply block outline-0 bg-transparent w-full py-2 px-2 text-neutral-600 border border-gray-500 text-sm;
		}
		> div {
			@apply w-full;
		}
		&--td {
			@apply table-cell w-auto pt-5;
			input[type="text"],
			input[type="email"],
			input[type="password"],
			input[type="number"],
			input[type="tel"],
			input[type="url"] {
				@apply border border-neutral-200 px-2 py-2 border-solid;
			}
		}
	}
}

.react-responsive-modal-modal {
	@apply min-w-[80%];
}

input[type="submit"],
button[type="submit"] {
	@apply bg-midBlue text-white px-4 py-2 rounded-sm hover:bg-darkBlue transition-all duration-300 cursor-pointer text-sm;
}

.resetButton {
	@apply px-4 py-2 rounded-sm transition-all duration-300 cursor-pointer text-sm bg-transparent text-darkBlue hover:bg-darkBlue hover:text-white border border-darkBlue;
}

.group {
	@apply bg-white px-9 py-12 rounded-lg shadow-lg;
}

.filterList {
	@apply pt-4 px-4;
	> li {
		@apply w-full p-1;
		> div {
			@apply text-xs;
		}
	}
	label {
		@apply text-xs font-bold pb-1 block text-darkBlue;
	}
	.Select {
		div[class*="control"] {
			@apply min-h-[25px];
		}
		div[class*="indicatorContainer"] {
			@apply p-[5px];
		}
		div[role="option"] {
			// react-select dropdown options
			@apply text-[13px];
		}
	}
	input[type="text"] {
		@apply inline-block border border-neutral-200 rounded-md px-2 py-1 text-sm w-full;
	}
}

.topBar {
	div[class*="indicatorContainer"] {
		@apply p-[0px];
	}
	div[class*="control"] {
		@apply min-h-[25px];
	}
	div[class*="ValueContainer"] {
		@apply text-xs font-normal;
	}
	div[role*="option"] {
		@apply text-xs;
	}
}

div[role="option"] {
	// react-select dropdown options
	@apply text-[13px];
}

.Select {
	&.smallText {
		div[role="option"] {
			@apply text-xs;
		}
	}
	div[class*="singleValue"] {
		@apply text-xs;
	}
}

.EntryTable {
	input {
		@apply border border-neutral-200 rounded-md xl:px-2 py-1 text-xs w-full;
	}
	tr {
		&:nth-child(even) {
			@apply bg-neutral-100;
		}
	}
	td {
		@apply py-1 px-2;
		border-bottom: 1px solid #eee;
	}
}

.filters {
	button[type="button"] {
		@apply bg-transparent text-darkBlue hover:bg-darkBlue hover:text-white border border-darkBlue;
	}
}

.app {
	&__popup {
		@apply min-w-[425px] max-w-[450px];
		h2 {
			@apply text-darkBlue text-xl font-bold mb-3;
		}
		table {
			@apply w-full border border-neutral-200;
			tr {
				&:nth-child(even) {
					@apply bg-neutral-100;
				}
			}
			td {
				@apply px-2 py-1 text-xs border border-r-neutral-200 text-neutral-700;
				&:first-child {
					@apply w-[35%] text-slate-600 font-semibold;
				}
			}
		}
	}
}

.react-responsive-modal-modal {
	&.small {
		@apply min-w-[30%];
	}
	&.addHeight {
		@apply min-h-[350px];
		div[class*="MenuList"] {
			@apply max-h-[200px];
		}
	}
}

.btn {
	@apply bg-darkBlue text-white px-4 py-1 rounded-md hover:bg-midBlue transition-all duration-300 cursor-pointer;
}

.smallBtn {
	@apply px-2 py-1 bg-midBlue text-white rounded-md text-xs inline-block cursor-pointer hover:bg-midBlue/80 transition duration-300;
}

.onboardForm {
	@apply mt-8 w-7/12;
	label {
		@apply block text-darkBlue;
	}
	input[type="password"] {
		@apply block border border-neutral-200 rounded-md px-2 py-1 text-sm w-full;
	}
}